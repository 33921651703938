import React from "react";
// import { Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";

import imgHW from "../../assets/image/svg/l6-hero-wave.svg";
import imgHWD from "../../assets/image/svg/l6-hero-wave-dark.svg";
import imgS1 from "../../assets/image/l6/png/l6-hero-shape1.png";
import imgS2 from "../../assets/image/l6/png/l6-hero-shape2.png";
import imgS3 from "../../assets/image/l6/png/l6-hero-shape3.png";
//import imgHero from "../../assets/image/pear-dashboard.png";
import ebookCover from "../../assets/image/Pear_2022_eCommerceTrends_eBook.jpg";

import { Select } from "../../components/Core";
import styled from "styled-components";

const GraphicImg = styled.img`
  margin-top: -65px;
  margin-left: -20px;
`;


const Unit = () => {
  const [state, handleSubmit] = useForm("mnqwvnwj");
  const ResponseMsg = () => {
    if (state.succeeded) {
      window.open(
        "https://s3.amazonaws.com/assets.pearcommerce.com/pear-commerce-cpg-ecommerce-trends-2022",
        "Download"
      );
      return <p>Thanks! Your Download will begin shortly.</p>;
    } else {
      return <p> </p>;
    }
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="hero-area-03 bg-default pt-18 pb-5 position-relative">
        <Container
          fluid
          className="bg-default"
          css={`
            max-width: 1450px;
          `}
        >
          <Row className="mx-0 mx-sm-24 mb-6 text-center text-lg-left justify-content-center">
            <Col className="align-items-left" lg="7">
              <h1 className="gr-text-5 text-dark-green">
                2022 CPG E-Commerce Trends <br />
                Driving Positive Return on Retail Ad Spend
              </h1>
            </Col>
            <Col lg="5"></Col>
          </Row>
          <Row className="mx-0 mx-sm-24 justify-content-center">
            <Col className="mt-0" lg="7">
              <div className="align-items-left">
                <Row>
                  <Col lg="4" className="px-24 px-md-30 px-lg-6 mt-4 mb-12">
                    <img
                      src={ebookCover}
                      className="w-100 hilite-green rounded-lg"
                    />
                  </Col>
                  <Col lg="8" className="mb-12">
                    <div className="text-dark">
                      <p className="gr-text-8">
                        Consumer Packaged Goods (CPG) brands and retailers have
                        recently experienced a high level of new opportunity and
                        change. Marketplaces are rapidly evolving with new
                        complexities every quarter.
                      </p>
                      <p className="gr-text-8">
                        Growth in e-commerce sales shows no signs of stopping,
                        so now is the time for CPG brands to position themselves
                        to benefit from these continued shifts in consumer
                        behavior.
                      </p>
                      <p className="gr-text-8">
                        Pear Commerce has compiled a list of six e-commerce
                        trends helping CPGs drive positive return on their
                        retail ad spend. Enter your information to learn more
                        about the trends and how Pear can help you take
                        advantage of them.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="px-12" lg="5">
              <div className="section-title">
                <h1 className="gr-text-6 mb-6 text-dark-green">
                  Download the eBook
                </h1>
              </div>

              <div className="subscribe-form mb-8">
                <form
                  className="cta-form light-mode"
                  onSubmit={handleSubmit}
                  id="contact-form"
                  action="https://formspree.io/f/mlearywe"
                  method="POST"
                  name="website-contact"
                  data-netlify-honeypot="bot-field"
                >
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaFirstName">First Name </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaFirstName"
                      placeholder=""
                      name="firstName"
                      required
                    />
                    <ValidationError
                      prefix="firstName"
                      field="firstName"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaLastName">Last Name </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaLastName"
                      placeholder=""
                      name="lastName"
                      required
                    />
                    <ValidationError
                      prefix="lastName"
                      field="lastName"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaTitle">Title </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaTitle"
                      placeholder=""
                      name="title"
                    />
                    <ValidationError
                      prefix="title"
                      field="title"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaCompany">Company </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaCompany"
                      placeholder=""
                      name="company"
                    />
                    <ValidationError
                      prefix="company"
                      field="company"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaEmail">Email </label>
                    <input
                      type="email"
                      className="form-control gr-text-11"
                      id="ctaEmail"
                      placeholder=""
                      required=""
                      name="_replyto"
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="_replyto"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaPhone">Telephone </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaPhone"
                      placeholder=""
                    />
                    <ValidationError
                      prefix="Phone"
                      field="phone"
                      errors={state.errors}
                    />
                  </div>
                  <Button type="submit" className="rounded-8 my-4">
                    Submit
                  </Button>
                  <div className="form-group my-4 gr-text-8 text-dark">
                    <ResponseMsg />
                  </div>
                </form>
              </div>
              {/* <div className="form-bottom excerpt text-center">
                <p className="gr-text-11 gr-text-color-opacity mb-0">
                  We’ll never share your details with third parties.
                  <br className="d-none d-xs-block" />
                  View our Privacy Policy for more info.
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Unit;
